import React, { Component } from "react";
import Markets from "./components/markets";
import Plans from "./components/plans";
import ComboBar from "./components/combo_bar";
import { markets } from "./data/markets_data";
import { plans } from "./data/plans_data";

export default class App extends Component {
  state = {
    markets: markets,
    plans: plans,
  };

  toggleMarket = (market) => {
    const selected = !market.selected;
    const markets = this.state.markets.map((m) =>
      m.id === market.id ? { ...market, selected } : m
    );
    this.setState({ markets });
  };

  availablePlans = () => {
    const planCode = this.state.markets
      .filter((market) => market.selected)
      .map((market) => market.id)
      .sort()
      .join("");

    return this.state.plans.filter((plan) => plan.code == planCode);
  };

  render() {
    return (
      // // combos off
      // <React.Fragment>
      //   {/* <div className="t-lg b">PASSO 1</div>
      //   <div className="t-nm t-light">
      //     Escolha <b>um ou mais</b> mercados de interesse
      //   </div>
      //   <div className="half space"></div>
      //   <Markets
      //     markets={this.state.markets}
      //     onToggleMarket={this.toggleMarket}
      //   />
      //   <ComboBar markets={this.state.markets} /> */}
      //   {/* <div className="two spaces"></div> */}
      //   <div className="space"></div>
      //   <div className="t-hg b blue">ESCOLHA SEU PLANO</div>
      //   <div className="t-md blue">
      //     Selecione o período mais adequado
      //   </div>
      //   <div className="two spaces"></div>
      //   <Plans plans={this.availablePlans()} />
      //   <div className="half space"></div>
      //   <div className="card small-margin">
      //     <div className="t-md b upcase blue">
      //       Aviso de Renovação Automática
      //     </div>
      //     <div className="t-nm blue">
      //       Sua assinatura é renovada automaticamente por iguais e sucessivos
      //       períodos. Você pode cancelar a qualquer momento.
      //     </div>
      //   </div>
      // </React.Fragment>
      

      // combos on
      <React.Fragment>
        <div className="t-lg b">PASSO 1</div>
        <div className="t-nm t-light">
          Escolha <b>um ou mais</b> mercados de interesse
        </div>
        <div className="half space"></div>
        <Markets
          markets={this.state.markets}
          onToggleMarket={this.toggleMarket}
        />
        <ComboBar markets={this.state.markets} />
        <div className="two spaces">
          <div className="row stretch">
            <div className="column">
              <div className="card small-margin green">
                <div className="t-sm b upcase green">
                  Aviso de Renovação Automática
                </div>
                <p className="t-sm">
                  Sua assinatura é renovada automaticamente por iguais e sucessivos
                  períodos. Você pode cancelar a qualquer momento.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="card small-margin purple">
                <div className="t-sm b purple upcase">
                  Lembrete de automação
                </div>
                <p className="t-sm t-default-color">
                  Para ter acesso ao sistema automático da Anny com nossas Calls, será necessário realizar a compra através do site da Anny. Os valores e planos são os mesmos.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column"></div>
            <div className="column">
              <div className="half space"></div>
              <div className="row">
              <div className="column">
                <a target="_blank" href="https://app.anny.trade/invite/BVAQ5OZ0NW2K" className="button purple full">AUTOMATIZAR PAR BTC</a>

              </div>
              <div className="column">
                <a target="_blank" href="https://app.anny.trade/invite/YZ37M7X3Z0PE" className="button purple full">AUTOMATIZAR PAR USDT</a>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="t-lg b">PASSO 2</div>
        <div className="t-nm t-light">
          Selecione o plano e período mais adequado
        </div>
        {/* <div className="space">
          <div className="area-red space t-center">
            <div className="container">Vendas Interrompidas para migração de clientes. Retorna hoje 18/03/2022 às 17h ou antes.</div>
          </div>
        </div> */}
        <div className="half space"></div>
        <Plans plans={this.availablePlans()} />
        <div className="half space">
          
        </div>

        
        
      </React.Fragment>
    );
  }
}
