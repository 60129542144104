import Player from '@vimeo/player';

document.addEventListener('DOMContentLoaded', () => {


  // objects

    const video = document.querySelector('.video')
    if (video){
      const player = new Player('copy-video', {
        id: 447240063
      })
      const videoThumb = document.querySelector('.video-thumb')
      const closeButton = document.querySelector('.video .close-button')
  
    // actions
  
      // open video
      const openVideo = () => {
        video.classList.add('active')
        player.play()
      }
  
      // close video
  
      const closeVideo = () => {
        video.classList.remove('active')
        player.pause()
      }
  
      // events
  
      videoThumb.addEventListener('click', openVideo)
      closeButton.addEventListener('click', closeVideo)
      video.addEventListener('click', closeVideo)
  
      document.querySelectorAll('.button.open-video').forEach( button => {
        button.addEventListener('click', openVideo)
      })
    }
})
