const animate = (quotes, current_index) => {
  let previousQuote = current_index == 0 ? quotes[quotes.length-1] : quotes[current_index - 1]
  let currentQuote = quotes[current_index]
  previousQuote.classList.remove('active')
  currentQuote.classList.add('active')

  let nextIndex = current_index + 1
  setTimeout(() => {
    if (nextIndex == quotes.length) {
      animate(quotes, 0)
    } else {
      animate(quotes, nextIndex)
    }
  }, 6000);
}

document.querySelectorAll('.rotate-quotes').forEach(block => {
  
  let quotes = Array.from(block.querySelectorAll('blockquote'))

  animate(quotes, 0)
})

