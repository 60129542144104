export default class Money {
    static print (val, curr='brl') {
        val = Number(val)
        switch (curr) {
            case 'brl':
                return(new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(val));
            case 'usd':
                return(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val));
            case 'eur':
                return(new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(val));
            default:
                throw("I don't how to print this currency.");
        }
    }
}