import React, { Component } from "react";
import Money from "../../../helpers/money";

export default class Plan extends Component {
  monthsLine() {
    const { months } = this.props.plan;
    if (months == 1) return `${months} mês`;
    else return `${months} meses`;
  }

  economyLine() {
    const { economy, discount } = this.props.plan;

    if (economy)
      return (
        <div className="t-nm green t-center">
          <b className="green">{discount}%</b>{" "}
          <span className="green">({Money.print(economy)})</span> de economia.
        </div>
      );
    return (
      <div className="t-nm t-light t-center">Não há desconto neste plano.</div>
    );
  }

  render() {
    const { price, economy, name, months, eduzz } = this.props.plan;
    const perMonthPrice = price / months;
    return (
      <div className="column">
        <div className="card">
          <div className="t-lg b yellow t-center upcase">{name}</div>
          <div className="space"></div>
          <div className="t-lg b t-center">
            <b>{Money.print(perMonthPrice)}</b>
            <span className="t-md"> / mês</span>
          </div>
          <div className="space"></div>
          <div className="t-sm t-light t-center">
            Cobrança recorrente de <br/><b>{Money.print(price)} a cada {this.monthsLine()}</b>.
          </div>
          <div className="two spaces">
            <button
              onClick={() => Eduzz("Widget", { id: eduzz }, this)}
              className="buy button lg"
            >
              COMPRAR
            </button>
          </div>
          <div className="half space"></div>
          {this.economyLine()}
        </div>
        <div className="half space phone-only"></div>
      </div>
    );
  }
}
