import React from "react";

const steps = function (current, total, discount = 10) {
  const array = Array.from({ length: total }, (v, k) => {
    const cssClass =
      current === 0
        ? "combo-bar-step zero"
        : current === k + 1
        ? "combo-bar-step current"
        : "combo-bar-step";
    return (
      <div key={k} className={cssClass}>
        {k * discount}%
      </div>
    );
  });
  return array;
};

export default function ComboBar(props) {
  const total = props.markets.length;
  const current = props.markets.filter((market) => market.selected).length;
  const discount = 20;
  return (
    <React.Fragment>
      <div className="space"></div>
      <div className="row responsive">
        <div className="column column-25">
          <div className="container">
            <div className="t-nm b">Desconto no Combo</div>
            <div className="t-sm t-light">
              {/* Quanto mais mercados você selecionar, maior o{" "} */}
              Selecione as duas modalidades para ganhar{" "}
              <b className="green">desconto.</b>
            </div>
          </div>
        </div>
        <div className="column column-75">
          <div className="combo-bar">{steps(current, total, discount)}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
