import React, { Component } from "react";
import Plan from "./plan";

class Plans extends Component {
  render() {
    if (this.props.plans && this.props.plans.length > 0)
      return (
        <div className="row stretch">
          {this.props.plans.map((plan) => (
            <Plan key={plan.id} plan={plan} />
          ))}
        </div>
      );

    return(
      <React.Fragment>
        <div className="card t-sm">Escolha os mercados de interesse no <b>passo 1</b> para ver os planos disponíveis.</div>
      </React.Fragment>
    );
  }
}

export default Plans;
