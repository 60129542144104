import React from "react";
import Market from "./market";

export default function Markets(props) {
  return (
    <div className="row">
      {props.markets.map((market) => (
        <Market
          key={market.id}
          market={market}
          onToggleMarket={() => props.onToggleMarket(market)}
        />
      ))}
    </div>
  );
}
