export
const markets = [{
    name: 'Criptos BTC',
    id: 'b',
    selected: false
  },
  {
    name: 'Criptos USDT',
    id: 'u',
    selected: false
  },
  // {
  //   name: 'Forex',
  //   id: 'f',
  //   selected: true
  // },
  // {
  //   name: 'Commodities',
  //   id: 'c',
  //   selected: false
  // },
]