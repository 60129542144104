import React from "react";
import uncheckedIcon from "images/ecalls/icon_circle_orange.png";
import checkedIcon from "images/ecalls/icon_checked_circle_ice.png";

export default function Market(props) {
  const market = props.market;
  return (
    <div className="column">
      <div
        className={market.selected ? "selected market card" : "market card"}
        onClick={props.onToggleMarket}
      >
        <div className="market-box">
          <div className="icon-area">
            <img
              className="unchecked"
              src={uncheckedIcon}
              alt="Market selection checkbox"
            />
            <img
              className="checked"
              src={checkedIcon}
              alt="Market selection checkbox"
            />
          </div>
          <div className="market-title">{market.name}</div>
        </div>
      </div>
    </div>
  );
}
