export
const plans = [{
    id: 1,
    name: 'Mensal',
    desc: 'Cripto BTC Mensal',
    months: 1,
    code: 'b',
    price: 135,
    eduzz: '342284'
  },
  {
    id: 2,
    name: 'Trimestral',
    desc: 'Cripto BTC Trimestral',
    months: 3,
    code: 'b',
    price: 292.00,
    eduzz: '342287',
    discount: 28,
    economy: 113
  },
  {
    id: 3,
    name: 'Anual',
    desc: 'Cripto BTC Anual',
    months: 12,
    code: 'b',
    price: 948,
    discount: 41,
    economy: 672,
    eduzz: '404008'
  },
  {
    id: 4,
    name: 'Mensal',
    desc: 'Ações Mensal',
    months: 1,
    code: 'a',
    price: 150,
    eduzz: '342257'
  },
  {
    id: 5,
    name: 'Trimestral',
    desc: 'Ações Trimestral',
    months: 3,
    code: 'a',
    price: 324,
    eduzz: '342262',
    discount: 28,
    economy: 126
  },
  {
    id: 6,
    name: 'Anual',
    desc: 'Ações Anual',
    months: 12,
    code: 'a',
    price: 1056,
    discount: 41,
    economy: 744,
    eduzz: '403897'
  },
  {
    id: 7,
    name: 'Mensal',
    desc: 'Ações + Criptos BTC Mensal',
    months: 1,
    code: 'ab',
    price: 256,
    eduzz: '342288',
    discount: 10,
    economy: 29
  },
  {
    id: 8,
    name: 'Trimestral',
    desc: 'Ações + Criptos BTC Trimestral',
    months: 3,
    code: 'ab',
    price: 554,
    eduzz: '342290',
    discount: 35,
    economy: 301
  },
  {
    id: 9,
    name: 'Anual',
    desc: 'Ações + Criptos BTC Anual',
    months: 12,
    code: 'ab',
    price: 1803.60,
    discount: 47,
    economy: 1616.40,
    eduzz: '404017'
  },
  {
    id: 4,
    name: 'Mensal',
    desc: 'Cripto USDT Mensal',
    months: 1,
    code: 'u',
    price: 135,
    eduzz: '1320981'
  },
  {
    id: 5,
    name: 'Trimestral',
    desc: 'Cripto USDT Trimestral',
    months: 3,
    code: 'u',
    price: 292,
    eduzz: '1320969',
    discount: 28,
    economy: 113
  },
  {
    id: 6,
    name: 'Anual',
    desc: 'Cripto USDT Anual',
    months: 12,
    code: 'u',
    price: 948,
    discount: 41,
    economy: 672,
    eduzz: '1320953'
  },
  {
    id: 7,
    name: 'Mensal',
    desc: 'Criptos BTC + USDT Mensal',
    months: 1,
    code: 'bu',
    price: 216,
    eduzz: '1321001',
    discount: 20,
    economy: 54
  },
  {
    id: 8,
    name: 'Trimestral',
    desc: 'Criptos BTC + USDT Trimestral',
    months: 3,
    code: 'bu',
    price: 496.40,
    eduzz: '1320993',
    discount: 39,
    economy: 313.6
  },
  {
    id: 9,
    name: 'Anual',
    desc: 'Criptos BTC + USDT Anual',
    months: 12,
    code: 'bu',
    price: 1516.8,
    discount: 53,
    economy: 1723.2,
    eduzz: '1320989'
  },
]
